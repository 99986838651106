<template>
  <div class="Awarp">
    <dashCard
      class="cdzBox4"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">使用情况</template>
      <template slot="aside"></template>
      <div class="card-content1">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
      <div class="card-content2">
        <div>
          <span>电力容量</span>
          <span>
            <font>{{ volume }}</font>
            <span> kWh</span>
          </span>
        </div>
        <div>
          <span>今日高峰需求电量</span>
          <span>
            <font>{{ peak }}</font>
            <span> kWh</span>
          </span>
        </div>
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <cdzBox4 :option="option" :isModal="true" :orginSearchObj="searchObj" />
      </div>
    </a-modal>
  </div>
</template>
    <script>
export default {
  name: "cdzBox4",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      volume: "",
      peak: "",
      panelOpen: false,
      searchObj: {},
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["#51A34B", "#B4D0FF", "#51A34B"],
    };
  },
  computed: {
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    //每月报警预警数
    getData() {
      this.isLoading = true;
      this.detailInfo = {};
      let data = {
        deptCode: this.currentSelectDeptInfo.CODE,
      };
      let url = Config.dev_url + "/api-apps/charger/stats";
      var chart1 = {};
      this.$axios.post(url, data).then((res) => {
        if (res.data.data.length == 0) {
          this.volume = 0;
          this.peak = 0;
          chart1 = {
            min: 0,
            max: 0,
            value: 0,
          };
        } else {
          this.volume = res.data.data[0].电力容量;
          this.peak = res.data.data[0].今日高峰;
          chart1 = {
            min: res.data.data[0].min,
            max: res.data.data[0].max,
            value: res.data.data[0].value,
          };
        }
        this.detailInfo.chart1 = chart1;
        console.log(this.detailInfo.chart1);
        this.initEchart();
        this.isLoading = false;
      });
      
    },
    initEchart() {
      var { min, max, value } = this.detailInfo.chart1;

      var option = {
        animation: false,
        layoutAnimation: false,
        tooltip: {
          trigger: "item",
        },
        series: [
          // 背景
          {
            animation: false,
            name: "使用情况",
            radius: "100%",
            type: "gauge",
            center: ["50%", "50%"],
            splitNumber: 12,
            // 展示当前进度
            progress: {
              show: true,
              width: 8,
              itemStyle: {
                color: "#DBDBDB",
              },
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              show: false,
            },
            // 刻度标签
            axisLabel: {
              show: false,
            },
            // 表盘中指针的固定点
            anchor: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内容
          {
            name: "",
            type: "gauge",
            radius: "100%",
            center: ["50%", "50%"],
            min: min,
            max: max,
            zlevel: 10,
            splitNumber: 12,
            // 展示当前进度
            progress: {
              show: true,
              width: 8,
              itemStyle: {
                color: "#51A34B",
                fontFamily: "ABBvoice_WCNSG_Rg",
              },
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              show: false,
            },
            splitNumber: 1,
            // 刻度标签
            axisLabel: {
              show: true,
              padding: [0, 10, 0, 10],
              distance: -30,
            },
            // 表盘中指针的固定点
            anchor: {
              show: false,
            },
            detail: {
              borderRadius: 8,
              offsetCenter: ["0%", "-5%"],
              fontSize: 42,
              fontWeight: "bolder",
              formatter: "{value}",
              color: "#696969",
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            data: [
              // {
              //   value: value,
              //   name: "使用情况(kW)",
              // },
            ],
          },
        ],
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.cdzBox4 {
  .card-content1 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
  .card-content2 {
    height: 93px;
    padding-top: 50px;
    padding: 28px 0 0;
    // height: 68px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: column;
      span {
        &:nth-child(1) {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }
        &:nth-child(2) {
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          line-height: 20px;
          font {
            font-size: 32px;
            font-weight: 500;
            color: #1d2129;
            line-height: 40px;
          }
        }
      }
    }
  }
}
</style>
